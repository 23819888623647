
:root {

  // COLORS
  --primary: #59BB67;
  --primary-rgb: 89,187,103;

  --primary-medium: #3AC66D;
  --primary-medium-rgb: 58, 198, 109;

  --secondary: #20A4F3;
  --secondary-rgb: 32,164,243;

  --alert: #FF3366;
  --alert-rgb: 255,51,102;

  --warning: #FF9133;

  --black: #02213B;
  --black-rgb: 2,33,59;

  --gray: #5D737E;
  --gray-rgb: 93,115,126;

  --gray-light: #eaebeb;

  --graysboro: #dadee1;

  --light: #F6F7F8;
  --light-rgb: 246,247,248;

  --white: #FFFFFF;
  --white-rgb: 255,255,255;

  --purple: #B059BB;

  // BUTTON STATES
  --clicable: #6d6e6d;
  --disabled: #A5CAA4;
  --hover: #224021;
  --active: #4A8C48;

  // PADDING AND MARGIN BOX
  --desk-size: 0.75rem;
  --desk-size-neg: -0.75rem;
  --mob-size: 0.25rem;
  --mob-size-neg: -0.25rem;

  // ACCORDION
  --accordion1: #59BE60;
  --accordion1-rgb: 89,190,96;
  --accordion2: #D1BA84;
  --accordion2-rgb: 209,186,132;
  --accordion3: #7993D1;
  --accordion3-rgb: 121,147,209;
  --accordion4: #D16F94;
  --accordion4-rgb: 209,111,148;

  // BORDER RADIUS
  --border-radius: 0.5rem;

  // BORDERS
  --border-width: 1px;
  --border-style: solid;

  // BOX SHADOW
  --box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

}
