@mixin mobile-s {
    @media (max-width: 320px) { @content; }
  }
  @mixin mobile-m {
    @media (max-width: 375px) { @content; }
  }
  @mixin mobile-l {
    @media (max-width: 425px) { @content; }
  }
  @mixin tablet {
    @media (max-width: 768px) { @content; }
  }
  @mixin laptop {
    @media (max-width: 1024px) { @content; }
  }
  @mixin laptop-l {
    @media (min-width: 1440px ) and (max-width: 2559px) { @content; }
  }
  @mixin screen-4k {
    @media (min-width: 2560px) { @content; }
  }
