@import "variables";
@import 'mixins';

mat-checkbox {
  .mat-checkbox-layout {
    margin-bottom: 1.25rem;

    * {
      color: var(--gray);
    }

    a {
      font-weight: 700;
      text-decoration: underline;
      text-wrap: wrap;
    }
  }
}

mat-radio-group {


  .mat-radio-checked{
    .mat-radio-outer-circle {
      border-color: var(--primary) !important;
    }
  }

  .mat-radio-inner-circle {
    background-color: var(--primary) !important;
  }

  label {
    margin-bottom: 0.75rem;

    color: var(--gray);
  }
}

mat-label {

  color: #5D737E;
  display: flex;
}

mat-form-field {
  .mat-form-field-label {


    &:focus {
      .mat-form-field-underline {
        border: 1px solid var(--gray) !important;
      }
    }
  }
  .mat-form-field-infix {
    border-top: none !important;
    width: 100% !important;
  }
  .mat-form-field-flex {
    background-color: var(--light);

    &:hover {
      background-color: var(--gray-light);
    }
  }

  .mat-form-field-underline {
    background-color: var(--white) !important;
  }



  .mat-form-field-prefix {
    padding: 0 1rem 0 0;
    top: 3px;
    color: var(--gray);
    font-size: 18px;
  }

  .mat-form-field-flex {
    padding: 1.3rem 1.3rem 1rem 1.3rem;
  }

  button {
    border: none !important;
  }
}

mat-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: var(--primary)!important;
  }

  .mat-form-field-label {
    color: var(--gray) !important;
  }
}

.form-group {
  .error {
    font-size: 0.90rem;
    margin: 0.2rem;
    color: var(--alert);
    position: absolute;
    margin-top: -18px;
  }

  .success {
    font-size: 0.90rem;
    margin: 0.2rem;
    color: var(--primary);
  }
}

.form, form {
  .form-group {
    display: flex;
  flex-direction: column;

    .form-item {
      padding: 0 0.75rem;
    }

  }

  .form-fluid {
    width: 100%;
  }

  .form-group {
    padding: 0.5rem 0 0 0;
    flex: 1;
  }
}


