

table {
  width: 100%;
  table-layout: auto;
  border: 0;
  border-collapse: collapse;
  padding: 0;
  font-size: 14px;

  tr {

    td {
      padding: var(--desk-size) 0;
    }
  }

}

.table-row-gray {
    background-color: var(--white) !important;
}

.table-default {
  // border: 1px solid var(--light);
  // margin-bottom: 1rem;
  // box-shadow: 0px 0px 2px 0px rgba(var(--gray), 0.75);
  border-bottom: 1px solid var(--graysboro);
  padding-bottom: 1.5rem;

  table thead tr {
    

    * {
      color: var(--black) !important;
    }
  }

  table tbody tr {

    * {
      color: var(--gray) !important;
    }
  }

  table tbody tr:nth-child(odd) {
    background-color: var(--light);
  }


  table tfoot tr {


    * {
      color: var(--black) !important;
    }
  }
}

.table-list-debits {
  width: 100%;
  font-weight: bold;

  tbody {
    width: 100%;

    tr {
      padding: 0;

      td {
        padding: 0;

        table {
          padding: 0;
          width: 100%;
          margin: 0 0 1rem 0;
          border: 1px solid var(--light);
          box-shadow: 0px 0px 3px 0px rgba(182, 182, 182, 0.75);

            thead {
              tr>td {
                background-color: var(--gray);
                color: rgba(var(--white-rgb), 0.8);
                padding: 0.5rem;
              }
            }

           tbody {
             tr {
              td{
                padding: 0.5rem;
                color: #505050;
                font-size: 0.8rem;
              }
             }
             
             tr:nth-child(odd) {
              background-color: var(--light) !important;
            }
          
            tr:nth-child(even) {
              background-color: var(--white) !important;
            }
           }
      }
  
        thead>tr {
          background-color: #666;
        }
      }
    }
    tr:nth-child(odd) {
      background-color: #fff !important;
    }
  
    tr:nth-child(even) {
      background-color: #fff !important;
    }
  }
  tfoot>tr {
    background-color: var(--gray);
    color: #fff;
  }
}

.last-child {
  text-align: right;
}

.child-with-input {
  width: 2rem;
}

.box-list {
  min-width: 100%;
    padding: 1rem;

    ul {
      list-style: none;

      li {
        padding: 0.5rem;

        p {
          margin: 0;

          span {
            font-weight: bold;
          }
        }
      }

      li:nth-child(odd) {
        background-color: #f5f4f4;
      }
    }
}


.table-list {
  width: 100%;

  tr {
    border-bottom: 1px solid var(--graysboro);
  }

  tr:last-child {
    border-bottom: 0 solid var(--graysboro);
  }
}
