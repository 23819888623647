@import "variables";
@import "mixins";

.drops {
    border: 1px solid #5d737e33;
    padding: 0.25rem;
    background-color: #f6f7f8;
    border-radius: 5px;
    width: 100%;
    display: block;
}

.drops-small {
    display: initial;
    width: fit-content;
    margin-right: var(--desk-size);
    font-weight: 600;
    font-size: 15px;
    padding: 0.25rem 1.25rem;
}

.success-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: var(--white);
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    flex: 1;
    box-shadow: 0px 0px 8px 4px #0000001A;

    header {
        display: flex;
        padding: var(--desk-size);
    }

    .body {
        // display: flex;
        padding: var(--desk-size);
    }

    footer {
        display: flex;
        padding: var(--desk-size);
    }

    * {
        margin: 0;
    }

    a {
        background: green;
        padding: 0.5rem;
        display: inline-block;
        color: #fff;
    }
}

.text-center {
    text-align: center;
}

.box-yellow {
    background-color: #ffd800a8;
    padding: 0.8rem;
    border: 2px solid #000;
    border-radius: 0.5rem;

    p {
        font-size: 0.9rem;
        color: #000;
        margin-bottom: 0.5rem;
        text-align: justify;
        line-height: 1rem;
    }
}

.article-list {
    article {
        margin-bottom: 0.75rem;
        border-bottom: 1px solid #dadee1;
    }
}

.txt-alert {
    color: var(--alert);
}

.txt-success {
    color: var(--alert);
}

.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.text-big {
    * {
        font-size: 2rem;
        line-height: 2rem;
        color: #000;
        text-transform: uppercase;
    }
}

.text-with-background {
    color: #ffffff !important;
    background-color: var(--primary);
    padding: 0.5rem;
    text-shadow: 1px 1px 4px #00000030;

    .bigger {
        font-size: 120%;
        font-weight: bolder;
    }
}

.circle {
    width: 2.3rem;
    height: 2.3rem;
    color: white;
    padding: 0.3rem;
    border-radius: 50%;
    font-size: 175%;
    font-weight: bold;
    display: inline-block;
    padding-top: 0.2rem;
}

.red {
    background-color: var(--alert) !important;
}

.green {
    background-color: var(--primary) !important;
}

//////////////////////
.text-small {
    line-height: 1rem;
}

.list-group {
    list-style: none;

    li {
        padding: 1.25rem 0;
        border-bottom: 1px solid var(--graysboro);
        text-align: center;

        * {
            color: var(--primary);
        }
    }

    li:last-child {
        border: 0;
    }
}
