
@import 'mixins';

// accordion
mat-accordion {
  width: 100%;
}

mat-expansion-panel {
  margin-top: 1.50rem !important;
  box-shadow:  0px 3px 18px #0000001A !important;

  .mat-expanded {
    .mat-expansion-panel-header-title {
      color: var(--secondary) !important;
    }
   }

}



  //  containers

  .cont {
    padding: 0 0.5rem;
  }

  .container-fluid {
    max-width: 100%;
    width: 100%;
    margin: auto;
  }

  .container-1024 {
    max-width: 1144px;
    width: 100%;
    margin: auto;
  }

  .container-body {
    padding: 0 0.5rem;
  }

  .container-960 {
    max-width: 960px;
    width: 100%;
    margin: auto;
  }

  .container-760 {
    max-width: 760px;
    width: 100%;
    margin: auto;
  }

  .container-880 {
    max-width: 880px;
    width: 100%;
    margin: auto;
  }

  .container-660 {
    max-width: 660px;
    width: 100%;
    margin: auto;
  }

  .container-550{
    max-width: 550px;
    width: 100%;
    margin: auto;
  }

  .container-360{
    max-width: 360px;
    width: 100%;
    margin: auto;
  }

  .content-header {
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;

     *  {
       text-align: center;

     }

  }

  .content-header-simple {
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;

     *  {
       text-align: center;

     }

  }

  .content-header::before {
    content: "";
    width: 100px;
    height: 3px;
    background-color: #59BE60;
    display: flex;
    margin: auto;
    margin-bottom: 1rem;
  }

  .content-footer {
    width: 100%;
    padding: 1rem;

     *  {
       text-align: center;
     }
  }

  .divisor-line::before {
    content: "";
    width: 100px;
    height: 3px;
    background-color: #59BE60;
    display: flex;
    margin: 1rem auto;
  }

  .content-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
   }

   .content-footer::before {
    content: "";
    width: 100px;
    height: 3px;
    background-color: #59BE60;
    display: flex;
    margin: auto;
  }

  .content-body {
    padding: 0.5rem;

    .content-body-header {
      * {
        text-align: center;
      }
    }
  }

  // rows e cols
  .content-row {
    display: flex;
    flex-direction: row;
    margin: var(--desk-size-neg);
    flex: auto;
  }

  .content-row>.box{
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: var(--desk-size);
    justify-content: stretch;
  }

  .content-col {
    display: flex;
    flex-direction: column;
    margin: var(--desk-size-neg);
    flex: auto;
  }

  .content-col>.box {
    display: flex;
    flex: auto;
    padding: var(--desk-size);
    align-items: stretch;
  }

  .body-layout{
      display: flex;
      flex-direction: column;

      > .box {
        margin-top: -0.5rem;
      }
  }

  .box-content {
    flex: 1;
  }

  .box-flex {
    display: flex;
  }

  .flex-0 {
    flex: 0 !important;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .flex-2 {
    flex: 2 !important;
  }

  .flex-3 {
    flex: 3 !important;
  }

  .flex-4 {
    flex: 4 !important;
  }

  .flex-5 {
    flex: 5 !important;
  }

  .flex-6 {
    flex: 6 !important;
  }

  .flex-7 {
    flex: 7 !important;
  }

  .flex-8 {
    flex: 8 !important;
  }

  .flex-9 {
    flex: 9 !important;
  }

  .flex-10 {
    flex: 10 !important;
  }

  .flex-11 {
    flex: 11 !important;
  }

  .flex-12 {
    flex: 12 !important;
  }

  .flex-initial {
    flex: initial !important;
  }

  .align-itens-center {
    align-items: center !important;
  }

  .justify-content {
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .display-desktop {
      display: flex;

      @include tablet {
        display: none;
      }
  }

  .display-tablet {
    display: none;

    @include tablet {
      display: flex;
    }

  }

  .display-mobile {
    display: none;

    @include mobile-l {
      display: flex;
    }

  }

  .alert-box {
    border: 1px solid #EBEBEB;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;

    * {
      color: #989898;
      font-size: 13px;
      margin: 0;
    }
  }

  .card {
    box-shadow: 0px 3px 18px #0000001A;
    border-radius: var(--border-radius);
    padding: 0.75rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    >header {
      padding: var(--desk-size);
      flex: 0;


      >div {
        border-bottom: 1px solid var(--graysboro);
        width: 100%;
      }

      * {
        color: var(--gray);

        span {
          vertical-align: middle;
          margin-right: var(--desk-size);
        }
      }
    }

    >.body {
      padding: var(--desk-size);
      flex: 1;
    }

    >footer {
      padding: var(--desk-size);
      flex: 0;
    }

    @include mobile-l {
      padding: 1.25rem;
    }
  }

  .card-big {
    padding: 2.25rem;

    @include mobile-l {
      padding: 1rem;
    }
  }


  .card-default {
    padding: 0;
    box-shadow: none;

    >header {
      padding: 0;

      .wrapper {
        border-bottom: 1px solid var(--graysboro);
      }

      * {
        font-weight: 600;
        color: var(--black);
      }

       span {
        font-size: 180%;
        vertical-align: middle;
        margin-right: 1rem;
       }
    }

    >.body {
      padding: 2.25rem;


      p, ul {
        font-size: 16px;

        @include tablet {
          font-size: 14px;
        }
      }
    }

    >footer {
      padding: 2.25rem var(--desk-size);
    }
  }

  .card-small {
    padding: 0.75rem;
  }

  .alert {
    padding: 1rem;

    p {
      margin-bottom: 0;
    font-size: 17px;

    span {
      font-size: 27px;
      vertical-align: middle;
      color:  var(--alert);
      margin-right: 1rem;
    }
    }
  }

  .warning {
    flex: 1;
    padding: var(--desk-size);
    box-shadow: 0px 3px 18px #0000001A;

    * {
      margin: 0;
    }
  }

  .pannel{
    box-shadow: 0px 3px 18px #0000001A;
    border-radius: var(--border-radius);
    padding: 3.75rem 2.5rem 3.75rem;

    @include mobile-l {
      padding: 3rem 1.25rem 2.5rem;
    }

    .pannel-header {
      margin-bottom: 3.75rem;
      text-align: center;

      @include mobile-l {
        margin-bottom: 3rem;
      }

      @include mobile-l {
        margin-bottom: 2.25rem;
      }

      h1, h2{
        strong {
          color: var(--primary);
        }
      }

      h3 {
        color: var(--gray);

        @include mobile-l {
          font-size: 14px;
        }
      }
    }
    .pannel-body  {
      h1, h2{
        strong {
          color: var(--primary);
        }
      }
    }

    .pannel-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: var(--desk-size);
    }
  }

  .pannel-white {
    background-color: var(--white);
  }

  .pannel-light {
    background-color: var(--light);
  }

  .container-box {
    padding: var(--desk-size);
  }


  .mb-sm {
    margin-bottom: 1rem !important;
  }

  .mt-sm {
    margin-top: 1rem !important;
  }

  .pb-sm {
    padding-bottom: 1rem !important;
  }

  .pt-sm {
    padding-top: 1rem !important;
  }
  // ------------------------ //

  .pb-md {
    padding-bottom: 1.875rem !important;
  }

  .pt-md {
    padding-top: 1.875rem !important;
  }

  .mb-md {
    margin-bottom: 1.875rem !important;
  }

  .mt-md {
    margin-top: 1.875rem !important;
  }

  .pb-bg {
    padding-bottom: 3.75rem !important;
  }

  .pt-bg {
    padding-top: 3.75rem !important;
  }

  .mb-bg {
    margin-bottom: 3.75rem !important;
  }

  .mt-bg {
    margin-top: 3.75rem !important;
  }
  .ml-sm {
    margin-left: 1rem;
  }

  .border-bottom {
    border-bottom: 1px solid var(--graysboro);
  }

  .border-top {
    border-top: 1px solid var(--graysboro);
  }


  .flex {
    display: flex;
  }



  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .img-full {
    width: 100%;
    height: auto;
  }

  .img-home {
    max-width: 105%;
    position: relative;
    left: -50px;
  }

  .img_icon {
    max-width: 50px;
  }

  .center {
    display: block;
    margin: auto;
  }

  .backgound-title-gray {
    background-color: var(--light);
    padding-left: 1rem;
      padding-top: 1rem;
      margin-bottom: 1rem;
  }

  .container-header {
    text-align: center;
    padding: 0.5rem;
  }

  .grid-container {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: auto auto auto;
  }

  .grid {
    display: flex;
    flex: 1;
    flex-direction: column;

    .grid-header {
      display: flex;
      margin: 1rem;
      text-align: center;
      justify-content: center;
    }

    .grid-content {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin: -1rem;
    }
  }

  .component {
    display: flex;
    flex-direction: column;

    >header {
      * {
        font-weight: 500;
      }
    }

    >.body {

    }

    >footer {

    }
  }
  .display-none {
    display: none !important;
  }
  .alert-text {
    color: var(--alert);
  }
