@import 'variables.scss';

a {
  color: var(--gray);
  text-decoration: none;
  white-space: nowrap;
}

a:hover {
  color: var(--black);
  text-decoration: none;
}

button {
  font-size: 14px;
}

.btn_white {
  background-color: #fff;
  border: 0px;
}

.default-link {
  color: var(--primary);
  border-bottom: 1px solid;
}

.secondary-link {
  color: var(--secondary);
}

// --------------------------------------------------------------
.menu-tab {
  display: flex;

  .link {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    background-color: #cfcfcf;
    padding: 0.3rem;
    text-align: center;
    transition: all 300ms;
    margin: 0.3rem;

    &:hover {
      background-color: #CC0031;
    }

    a {
      font-size: 0.9rem;

      &:hover {
        color: #fff;
      }
    }
  } 
}
