@import "variables";

.btn {
  display: block;
  padding: 0.8rem;
  border-radius: 0.3rem;
  border: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 300ms;
  text-transform: uppercase;
  color: var(--white);
  white-space: nowrap;
  font-weight: 500;
}

.btn-block {
  flex: 1;
}

.btn-success {
  background-color: var(--primary);

  &:hover {
    background-color: rgba(var(--primary-rgb), 0.8);
    color: var(--white);
  }
}

.btn-alert {
  background-color: var(--alert);

  &:hover {
    background-color: rgba(var(--alert-rgb), 0.8);
    color: var(--white);
  }
}

.btn-gost {
  border: 1px solid var(--secondary);
  color: var(--secondary);
  padding: 0.74rem;

  &:hover {
    border:  1px solid rgba(var(--secondary-rgb), 0.8);
    background-color: rgba(var(--secondary-rgb), 0.3);
    color: var(--secondary);
  }  
}

.btn-default {
  background-color: var(--secondary);

  &:hover {
    background-color: rgba(var(--secondary-rgb), 0.8);
    color: var(--white);
  }
}

.btn-transparent {
  background-color: transparent;
  color: var(--gray);
  font-size: 2rem;
}

.btn-transparent-link {
  background-color: transparent;
  border: 0;
  color: var(--secondary);
  border-bottom: 1px solid var(--secondary);
  padding: 0.75rem 0;
  cursor: pointer;
}

.list-links {
  list-style: none;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-bottom: 0;
}


.list-links-vertical {
  list-style: none;

  li {
    margin-bottom: 0.5rem;
  }
}

.list-links-vertical-detail {
  list-style: none;

  li {
    padding: 1rem;

    a {
      span {
        margin-right: 1rem;
        border-right: 1px solid var(--gray);
        padding-right: 1rem;
      }
    }
  }
}







.btn-rounded-fluid {
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 300ms;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
  color: var(--primary);

  span {
    font-size: 80%;
  }
}

.form-button {
  width: 100%;
  display: block;
  background-color: var(--primary);
  color: #fff;
  font-weight: bold;
  border: 0px solid;
  margin-top: 15px;
  transition: 500ms all;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: var(--hover);
  }
}

.buttons-menu {
  display: flex;
  margin: 0 -0.5rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    font-weight: bold;

    input {
      display: none;
    }

    label,
    button {
      flex: 1;
      cursor: pointer;
      padding: 0.5rem;
      color: var(--gray);
      background: transparent;


      transition: all 300ms;
      border: 0;
      font-weight: 400;
      text-transform: uppercase;

      &:hover {
        background-color: var(--light);
      }
    }

    input[disabled] ~ label {
      cursor: default;
      background-color: #cfcfcf;
      color: #929292;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    }
  }

  .grey {
    label,
    button {
      border-bottom: 1px solid var(--primary) !important;
      color: var(--primary) !important;
    }
  }
}
