/* You can add global styles to this file, and also import other style files */

// IMPORT MATERIAL THEME
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@mdi/font/css/materialdesignicons.min.css";

// no ui slider
@import '~nouislider/distribute/nouislider.min.css';

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

// @font-face {
//   font-family: 'Roboto';
//   src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');
// }

// IMPORTS
@import 'variables';
@import 'mixins';
@import 'functions';
@import 'buttons';
@import 'links';
@import 'tables';
@import 'form';
@import 'texts';
@import 'layout';


// GLOBAL

* {
  //font-family: sans-serif;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
}

p {
  color: var(--gray);
  line-height: 1.75rem;
  margin-bottom: 0.75rem;


}

small {
  font-size: 12px;
}

ul, ol {
  color: var(--gray);
  list-style-position: inside;
  margin-bottom: 0.75rem;

  li {
    font-size: 14px;
  }
}

ol {
  li {
    font-size: 14px;
  }
}

#{headings(1,6)} {
  font-family: 'Roboto', sans-serif;
  color: var(--black);
  margin-bottom: 1.875rem;
  font-weight: 400;
}

h1 {
  font-size: 30px;
  margin-bottom: calc(30px * 1.3);

  @include mobile-l {
    margin-bottom: calc(24px * 1.3);
    font-size: 24px;
  }
}

h2 {
  font-size: 25px;
  margin-bottom: calc(25px * 1.3);

  @include mobile-l {
    margin-bottom: calc(20px * 1.3);
    font-size: 20px;
  }
}

h3 {
  font-size: 20px;
  margin-bottom: calc(20px * 1.3);


  @include mobile-l {
    margin-bottom: calc(18px * 1.3);
    font-size: 18px;
  }
}

h4 {
  font-size: 18.0px;
  margin-bottom: calc(18px * 1.3);

  @media only screen and (max-width: 768px) {
    font-size: 17.28px;
    margin-bottom: calc(17.28px * 1.3);
  }
}

h5 {
  font-size: 16.0px;
  margin-bottom: calc(16px * 1.3);
}

h6 {
  font-size: 14.00px;
  margin-bottom: calc(14px * 1.3);
}

.loading {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;

    p{
      margin-top: 1rem;
    }
  }


  .border-shadow-grid {
    display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .bsg-box {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0.5rem;
        padding: 0.5rem;
        max-width: 345px;
        min-width: 225px;

          header {
            text-align: center;
            flex: 0;
          }

          .body {
            flex: 1;
          }

          footer {
            * {
              font-style: italic;
            }
            flex: 0;
          }

          ul {
            margin-bottom: 1rem;
          }

        * {
          color: var(--black);
          list-style: none;
        }
      }

      .bsgb2 {
        border-bottom: #008000 5px solid;
        border-right: #008000 5px solid;
      }

      .bsgb1 {
        border-bottom: var(--gray) 5px solid;
        border-right: var(--gray) 5px solid;
      }
  }

  .banner-video {
    max-width: 600px;
    margin: auto;

    .banner-item-video {
      padding: 0;
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

       iframe {
         padding: 0;
         position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

         video {
          width: 100%    !important;
          height: auto   !important;
        }
       }
    }
  }


